@import "@/assets/styles/helpers/mixin";
@import "@/assets/styles/helpers/variables";

.section {
  padding: 48px 0;

  &.topNone {
    padding-top: 0;
  }

  &.bottomNone {
    padding-bottom: 0;
  }
}