.blur {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: blur(10px);
}
.image {
  height: 100%;
  max-width: 100%;
  z-index: 5;
  object-fit: cover;
}
