@font-face {
  font-family: 'Raleway';
  src: url('./Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('./Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('./Raleway-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
