@import '../../../assets/styles/helpers/mixin';
@import '../../../assets/styles/helpers/variables';
@import '@/assets/styles/templates.module.scss';

.newsCard {
  position: relative;
  max-width: 100%;
  padding: 32px 0;
  border-top: 1px solid $c_gray-light;

  &:first-child {
    padding-top: 0;
    border: none;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.body {
  display: flex;
  gap: 48px;
  max-height: 229px;
  position: relative;

  @include _744 {
    flex-direction: column;
    max-height: 100%;
  }

  @include _480 {
    gap: 32px;
  }
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  inset: 0;
}

.imgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  border-radius: 5px;
  max-width: 400px;
  height: 229px;
  width: 100%;
  overflow: hidden;

  img {
    border-radius: 5px;
  }

  svg {
    width: 100%;
    object-fit: cover;
  }

  @include _744 {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.content {
  //max-height: 229px;
  max-width: 872px;
  width: 100%;
}

.date {
  color: $c_gray;
  margin-bottom: 8px;
}

.title {
  font-size: $title;
  line-height: $title-height;
  font-weight: 600;
  margin-bottom: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @include _480 {
    font-size: $title-mob;
    line-height: $title-mob-height;
  }
}

.newsContent {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 400;
  line-height: 170%;

  @include _1150 {
    -webkit-line-clamp: 3;
  }

  @include _900 {
    -webkit-line-clamp: 2;
  }

  @include _744 {
    -webkit-line-clamp: 3;
  }
}
