@import '@/assets/styles/templates.module.scss';

.popup {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1000;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: auto;

  background-color: rgba(30, 27, 24, 0.61);
  overflow-y: scroll;
}

.content {
  position: relative;

  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 12px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 12px 0px rgba(34, 60, 80, 0.2);

  z-index: 100;

  @media (max-width: 576px) {
    margin: 130px 30px 100px;
  }
}
