@import '@/assets/styles/templates.module.scss';
.downloadBtn {
  text-decoration: none;
  color: $c_accent;
  position: relative;
  display: inline-block;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0 0 6px 0;
  font-weight: 400;
  line-height: 170%;

  &::before {
    content: '';
    width: 95%;
    height: 2px;
    background: rgba(83, 179, 116, 0.4);
    position: absolute;
    opacity: 0.4;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
