@import '@/assets/styles/templates.module';

.burger {
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  cursor: pointer;
  display: none;

  span {
    width: 30px;
    height: 2.5px;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $c_gray;
    transition: all 0.3s linear;

    &:nth-of-type(2) {
      top: calc(50% - 10px);
    }

    &:nth-of-type(3) {
      top: calc(50% + 10px);
    }
  }

  &.active {
    span {
      background-color: $c_dark;

      &:nth-of-type(1) {
        width: 0;
      }

      &:nth-of-type(2) {
        top: 50%;
        transform: translate(-50%, 0%) rotate(45deg);
      }

      &:nth-of-type(3) {
        top: 50%;
        transform: translate(-50%, 0%) rotate(-45deg);
      }
    }
  }

  @media screen and (max-width: 1250px) {
    display: block;
  }
}
