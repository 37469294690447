* {
  color: #23242b;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-variant-numeric: lining-nums;
  font-size: 16px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
  text-decoration: none;
  vertical-align: baseline;
}


ul,
ol {
  list-style-type: none;
}
a {
  text-decoration: none;
}
html {
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;

}
html {
  overflow-x:hidden;
  overflow-y: unset; 
  scrollbar-width: 17px;
  // padding-right: 17px;
}
body {
  margin: 0;

}
html,
body {
  position: relative;
  min-height: 100vh;
  
  
}
#__next{
  display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: flex-end;
}
main {
  display: block;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 1.15;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
html,
body,
#root,
main {
  height: 100%;
}

#__next > div {
  height: 100%;
}

#__next {
  height: fit-content;
  background-color: #fff;
}
.swiper-button-next,.swiper-button-prev {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(93,105,99,.3);
  color: #b4b4b9;
  height: 38px;
  opacity: .7;
  width: 38px
}
.swiper-button-next:after, .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 14px !important;
  color: #b4b4b9;
  font-variant: normal;
  letter-spacing: 0;
  line-height: 1;
  text-transform: none!important;
}