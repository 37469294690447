@import '@/assets/styles/helpers/mixin';

.row {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 15px;

  @include _744 {
    justify-content: center;
  }
}
