@import "../helpers/mixin";
@import "../helpers/variables";

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  line-height: 1.15;
  font-weight: var(--body-font-weight);
  color: var(--black);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1, h2, h3, h4, h5, h6 {
  font-size: $title;
  line-height: $title-height;
  font-weight: 600;
  //margin-bottom: 1.5rem;

  @include _480 {
    font-size: $title-mob;
    line-height: $sub-title-height;
  }
}

.titleHeader {
  font-size: 40px;
  line-height: $header-height;
  font-weight: 600;
  margin-bottom: 3rem;

  @include _480 {
    font-size: $header-mob;
    line-height: $header-mob-height;
  }
}
.swiper-button-next{
  margin-right: -5px;
}