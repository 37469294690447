@import '../../../assets/styles/helpers/mixin';
@import '../../../assets/styles/helpers/variables';
@import '../../../assets/styles/templates.module';

.categoryCard {
  width: calc(33% - 10px);
  min-height: 172px;
  position: relative;
  border: 1px solid $c_gray;
  border-radius: 8px;
  padding: 20px;

  > h2 {
    font-size: 16px;
    line-height: $default-height;
    font-weight: 600;
    margin-bottom: 10px;
  }

  @include _1024 {
    max-width: 49%;
  }

  @include _744 {
    max-width: 100%;
  }

  p {
    color: $c_dark;
  }
}

.icon {
  display: block;
  margin-bottom: 20px;
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  inset: 0;
}
