@import '@/assets/styles/templates.module.scss';
@import '@/assets/styles/skeleton.scss';

.item {
  @include skeletonSection();
  display: flex;
  gap: 20px;
  height: 269px;
  margin-bottom: 64px;

  @media screen and (max-width: 744px) {
    & {
      flex-direction: column;
      height: 390px;
    }
  }
}

.image {
  @include skeleton(400px, 229px);
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 400px;
  max-height: 229px;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 744px) {
    & {
      @include skeleton(100%, 229px);
      width: 100%;
    }
  }
}

.content {
  max-width: 872px;
  width: 100%;
}

.date {
  @include skeleton(150px, 19px);
  margin-bottom: 8px;
}

.title {
  @include skeleton(300px, 31px);
  margin-bottom: 24px;
}
.newsContent {
  @include skeleton(400px, 27px);
}
