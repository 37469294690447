@import '@/assets/styles/templates.module.scss';

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  height: 100%;
}
.content {
  position: relative;
  z-index: 10;

  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}
