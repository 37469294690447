@import '@/assets/styles/templates.module.scss';
@import '@/assets/styles/skeleton.scss';

.item {
  position: relative;
  // max-width: 427px;
  width: 427px;
  min-height: 462px;

  @include _480 {
    width: 440px;
    min-height: 100%;
  }
  @include _360 {
    width: 320px;
  }
}

.image {
  @include skeleton(427px, 240px);
  display: flex;
  max-width: 427px;
  height: 240px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.content {
  padding-top: 20px;
}

.date {
  @include skeleton(100px, 21px);
}

.status {
  @include skeleton(172px, 23px);
  margin-top: 22px;
}

.info {
  @include skeleton(220px, 27px);
  margin-top: 10px;
}

.price {
  @include skeleton(220px, 31px);
  margin-top: 8px;
}
.address {
  @include skeleton(400px, 20px);
  margin-top: 8px;
}
