@import '/assets/styles/templates.module';
@import '/assets/styles/helpers/mixin';

.container {
  display: flex;
  gap: 20px;

  @include _360 {
    margin-right: 0;
  }
}

.icon {
  width: 64px;
  height: 64px;

  svg {
    height: 100%;
    width: 100%;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    font-weight: 600;
    line-height: 170%;
    font-size: $fs_default;
    color: #1e1e1e;
  }

  span {
    font-weight: 400;
    line-height: 170%;

    a {
      color: $c_accent;
      font-weight: 400;
    }
  }
}

.closeBtn {
  cursor: pointer;
}