@import '@/assets/styles/templates.module.scss';

.numberCard {
  max-width: 424px;
  width: 100%;
  min-height: 141px;
  padding: 20px;
  background: $c_gray-light;
  border-radius: 8px;
}

.number {
  color: $c_dark;
  margin-bottom: 20px;
}

.text {
  color: #1e1e1e;
}

.helpText {
  color: $c_gray;
  margin-top: 10px;
}
