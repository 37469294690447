@import '/assets/styles/templates.module.scss';
@import '/assets/styles/helpers/mixin';

.container {
  display: flex;
  gap: 20px;

  @include _360 {
    margin-right: 0;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    font-weight: 600;
    line-height: 170%;
    font-size: $fs_default;
    color: #1e1e1e;
    margin-bottom: 12px;
  }
}

.closeBtn {
  cursor: pointer;
}

.links {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  a {
    display: flex;
    font-weight: 400;
    line-height: 170%;
    gap: 8px;
    align-items: center;
  }
}
