@import '@/assets/styles/helpers/mixin';
@import '@/assets/styles/helpers/variables';
@import '@/assets/styles/templates.module.scss';

.categoryCard {
  max-width: 426px;
  width: 100%;
  height: 169px;
  position: relative;
  border: 1px solid $c_gray;
  border-radius: 8px;
  padding: 20px;

  > h2 {
    font-size: 16px;
    line-height: $default-height;
    font-weight: 600;
    margin-bottom: 10px;
  }

  > p {
    font-size: 16px;
  }

  @include _480 {
    height: 104px;
  }
}

.icon {
  display: block;
  @include _480 {
    display: none;
  }
  margin-bottom: 20px;
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  inset: 0;
}
