@import "@/assets/styles/helpers/mixin";

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  > h1 {
    margin-bottom: 0;
  }

  @include _480 {
    display: block;
  }
}