@import '@/assets/styles/templates.module.scss';

.swiper-button-prev,
.swiper-button-next {
  width: 38px !important;
  height: 38px !important;

  border-radius: 50%;
  background-color: white;
  color: $c_gray;
  box-shadow: 0px 4px 4px 0px rgba(93, 105, 99, 0.3);
  opacity: 0.7;

  &::after {
    font-size: 14px;
  }
}

.swiper-button-next {
  padding-left: 3px;
  margin-right: -5px;
}

.swiper-button-prev {
  padding-right: 3px;
  margin-left: -5px;
}

.swiper-button-disabled {
  display: none;
}
