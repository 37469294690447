@import '../../../assets/styles/templates.module';

.advtBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 55px;
  padding: 14px 24px;
  max-width: 250px;

  color: #fff !important;
  border-radius: 5px;
  background: $c_accent;
  transition: 0.2s;

  font-size: 16px;
  text-align: center;

  &:hover {
    opacity: 0.5;
  }
}