

$animation-duration: 1.5s;

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));

  @return $randomNum;
}

@mixin skeleton($width, $height) {
  display: block;
  max-width: $width;
  width: 100%;
  height: $height;

  background-color: $c_accent;
  opacity: .4;

  border-radius: $br_default;
}

@mixin background-gradient {
  background-image: linear-gradient(
    90deg,
    transparent 0px,
    rgba(255, 255, 255, 0.5) 40px,
    transparent 80px
  );
}

@mixin skeletonSection {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    
    display: block;

    position: absolute;
    z-index: 10;

    width: 100%;
    height: 100%;

    @include background-gradient;
    animation: shine-lines $animation-duration infinite linear;
  }  
}

@keyframes shine-lines {
  0% {
    background-position: -200px;
  }

  100% {
    background-position: 900px;
  }
}