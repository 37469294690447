@import '../../../../assets/styles/helpers/variables';
@import '@/assets/styles/templates.module';

.menuWrapper {
  position: fixed;
  top: 83px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(30, 27, 24, 0.61);
  z-index: 999;
  transition: opacity 0.6s ease-in-out;
  opacity: 0;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: all;
    padding-right: 15px;
  }
}

.menu {
  max-width: 1320px;
  width: 100%;
  background: #fff;
  opacity: 0;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, -150%);
  border-radius: 8px;
  padding: 20px;
  transition: 0.4s ease-in-out;

  &.visible {
    transform: translate(-50%, 0%);
    opacity: 1;
  }
}

.menuContent {
  > h1 {
    font-size: $title;
    line-height: $title-height;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

.closeBtn {
  display: flex;
  justify-content: center;
  align-content: center;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.rowCard {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  height: fit-content;
}
