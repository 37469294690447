@import '@/assets/styles/templates.module';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  max-height: 400px;
  height: 100%;
  width: 100%;

  margin-bottom: 48px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $br_default;
  }
}
