@import '@/assets/styles/templates.module';

.item {
  border-bottom: 1px solid $c_gray-light;

  &:first-child {
    border-top: 1px solid $c_gray-light;
  }
}

.button {
  padding: 24px 48px 24px 0;
  font-weight: 500;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    width: 24px;
    height: 24px;
    background: url('../../../assets/icons/components/arrow_accordion.svg');
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.4s;
  }

  &[aria-expanded='true']::before {
    transform: translateY(-50%) rotate(180deg);
  }

  &.questionPage {
    color: #1e1e1e;
    font-size: 18px;
    line-height: 150%;
  }
  &.housesListPage {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #1e1e1e;
  }
}

.content {
  padding-bottom: 24px;
  padding-right: 50px;
  line-height: 27px;
  p {
    color: $c_dark;
  }

  img {
    width: 100%;
  }

  &.questionPage {
    p {
      color: #1e1e1e;
      font-weight: 400;
      line-height: 170%;
    }
  }
}
