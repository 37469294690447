@import '@/assets/styles/helpers/mixin';

.linksRow {
  display: flex;
  gap: 20px;

  @include _744 {
    margin-top: 24px;
  }
  @include _360 {
    .linkLogo {
      height: 46px;
    }
  }
}
