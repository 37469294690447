@import '@/assets/styles/templates.module';

.mobileMenuWrapper {
  position: fixed;
  top: 83px;
  left: 0;
  z-index: 999;
  width: 100%;
  height: calc(100vh - 70px);
  background: rgba(30, 27, 24, 0.61);
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
  pointer-events: none;

  &.visible {
    pointer-events: all;
    opacity: 1;
  }

  @include _650 {
    top: 70px;
  }
}

.mobileMenu {
  background: #fff;
  width: 360px;
  height: 100%;
  padding: 4px 0;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: 0.4s ease-in-out;
  transform: translateX(-100%);

  &.visible {
    transform: translateX(0%);
  }

  @include _480 {
    width: 100%;
    height: 100%;
  }
}

.accordionButton {
  padding: 12px 24px;
  font-weight: 600;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    background: url('../../../../assets/icons/components/icon_arrow.svg');
    width: 20px;
    height: 20px;
    right: 24px;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
    transition: 0.5s;
  }

  &[aria-expanded='true']::before {
    transform: translateY(-50%) rotate(180deg);
  }
}

.accordionLink {
  padding: 12px 24px;
  display: flex;
  gap: 10px;
  text-decoration: none;
  color: $c_black;
  align-items: center;
  background-color: transparent;
  width: 100%;

  span {
    font-weight: 600;
  }
}

.accordionElement {
  padding: 12px 24px;
  display: flex;
  gap: 10px;
  text-decoration: none;
  color: $c_black;
  font-weight: 500;
  align-items: center;
  background-color: transparent;
  width: 100%;
}

.link {
  color: $c_black;
  font-weight: 600;
  text-decoration: none;
  display: block;
  padding: 12px 24px;
}

.icon {
  display: flex;
  svg {
    width: 27px;
    height: 27px;
  }
}
.buttons {
  display: none;
  padding: 8px 24px;

  a {
    width: 100%;
  }
  button {
    width: 100%;
    padding: 8px;
    line-height: 1.7;
    font-weight: 400;
  }
  @include _650 {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.firstBtn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hintBtn {
  cursor: pointer;
  min-width: 20px;
  min-height: 20px;

  &.active {
    path {
      fill: $c_accent;
      &:first-child {
        stroke: $c_accent;
      }
    }
  }
}

.hint {
  position: relative;
  padding: 9px 8px;
  font-weight: 400;
  line-height: 170%;
  border: 1px solid $c_gray;

  > div {
    position: absolute;
    top: 50%;
    right: -6px;
    border-top: 1px solid $c_gray;
    border-right: 1px solid $c_gray;

    background: inherit;
    width: 10px;
    height: 10px;
    transform: translateY(-50%) rotate(45deg);
  }
}

.profile {
  display: none;

  @include _650 {
    display: block;
  }
}
