.accordionHeader {
  cursor: pointer;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  & > div:first-child {
    position: relative;

    &:before {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      height: 14px;
      display: flex;
      align-content: flex-start;
      justify-content: center;
      align-items: flex-start;
      // transition: transform 0.5s ease-in-out;
    }
  }
}

.accordionBody {
  height: 0;
  overflow: hidden;
  transition: height 0.5s;
}

.accordion {
  &[aria-expanded='true'] div:first-child div {
    &:before {
      transform: rotate(180deg);
    }
  }
}

.reverse {
  display: flex;
  flex-direction: column-reverse;
}
