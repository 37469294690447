@import '@/assets/styles/helpers/mixin';
@import '@/assets/styles/helpers/variables';
@import '@/assets/styles/templates.module.scss';

.wrapper {
  display: flex;
  justify-content: space-between;

  > h1 {
    margin-bottom: 48px;
  }

  &.indent {
    margin-bottom: 48px;
  }

  a {
    text-decoration: none;
    color: $c_accent;
    line-height: 170%;
    font-weight: 400;
  }

  @media screen and (max-width: 814px) {
    display: block;
  }

  @include _744 {
    display: block;
    // display: flex;
    // flex-direction: column;
  }
}

.header {
  max-width: 338px;
  width: 100%;

  > h1 {
    font-size: $title;
    line-height: $title-height;

    @include _480 {
      font-size: $title-mob;
      line-height: $title-mob-height;
    }
  }

  > h2 {
    font-size: 16px;
    line-height: $default-height;
    font-weight: 400;
    color: $c_gray;
    margin-bottom: 8px;
  }

  > p {
    margin-bottom: 24px;
    font-weight: 400;
    line-height: 170%;
  }

  > span {
    color: $c_dark;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
  }

  @include _1024 {
    max-width: 288px;
  }

  @media screen and (max-width: 814px) {
    max-width: 100%;
  }
  @include _744 {
    max-width: 100%;
  }
}

.content {
  max-width: 872px;
  width: 100%;

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 170%;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 170%;
    }

    a {
      width: fit-content;
      text-decoration: none;
      color: $c_accent;
      line-height: 170%;
      font-weight: 400;
    }
  }

  h2 {
    font-size: 16px;
    line-height: $default-height;
    font-weight: 400;
    color: $c_gray;
  }

  @include _1024 {
    max-width: 648px;
  }
  @media screen and (max-width: 814px) {
    max-width: 100%;
  }
  @include _744 {
    max-width: 100%;
  }
}
