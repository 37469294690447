@import '@/assets/styles/templates.module';

.header {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 83px;

  background-color: #fff;
  border-bottom: 1px solid $c_gray-light;
  transition: 0.4s transform ease-in-out;
  transform: translateY(-100%);

  z-index: 999;

  &.visible {
    transform: translateY(0%);
  }

  &.isActive {
    padding-right: var(--scroll-width);
  }

  @include _1024 {
    height: 83px;
  }

  @include _650 {
    height: 70px;
  }
}
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 1360px;
  width: 100%;

  margin: 0 auto;
  padding: 20px 20px;
}
.headerMap {
  @include _1150 {
    z-index: 500;
  }
}

.group {
  display: flex;
  gap: 24px;
  align-items: center;

  @include _650 {
    justify-content: space-between;
    width: 100%;
    gap: 0;
  }
}

.navList {
  display: flex;

  @media screen and (max-width: 1250px) {
    display: none;
  }
}

.dropdownBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  background-color: #fff;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }

  span {
    color: black;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Manrope';
    font-weight: 600;
    position: relative;
  }

  &.active > span {
    color: $c_accent;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      border: 1px solid $c_accent;
      bottom: -31px;
    }
  }

  &.activeHomeApplication > span:last-child {
    color: $c_accent;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      border: 1px solid $c_accent;
      bottom: -18px;
    }
  }
}

.homeApplicationBtn {
  @include flex-col-gap(8px);
  span {
    color: $c_accent;
    font-weight: 800;

    &:last-child {
      border-bottom: 1px solid $c_accent;
    }
  }
}

.buttons {
  display: flex;
  align-items: center;

  @include _650 {
    display: none;
  }
}

.hintBtn {
  margin-right: 12px;
  cursor: pointer;

  &.active {
    path {
      fill: $c_accent;
      &:first-child {
        stroke: $c_accent;
      }
    }
  }
}

.hint {
  position: relative;
  padding: 9px 8px;
  font-weight: 400;
  line-height: 170%;
  border: 1px solid $c_gray;

  > div {
    position: absolute;
    top: -6px;
    left: 50%;
    border-top: 1px solid $c_gray;
    border-right: 1px solid $c_gray;

    background: inherit;
    width: 10px;
    height: 10px;
    transform: translateX(-50%) rotate(-45deg);
  }
}
.hintContainer {
  z-index: 1000;
}

.advtBtn {
  display: flex;
  align-items: center;

  height: 43px;
  padding: 8px 12px;
  margin-right: 12px;

  color: #fff;
  border-radius: 5px;
  background: $c_accent;
  transition: 0.2s;

  font-size: 16px;

  &:hover {
    opacity: 0.5;
  }
}

.loginBtn {
  display: flex;
  align-items: center;

  height: 43px;
  padding: 8px 12px;

  color: $c_accent;
  border: 1px solid $c_accent;
  border-radius: 5px;

  font-size: 16px;
  text-decoration: none;
  transition: 0.2s;

  &:hover {
    opacity: 0.5;
  }
}

.profileBtn {
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.popover {
  position: absolute;
  display: flex;
  max-width: 1320px;
  // width: 100%;
  background: #fff;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -150%);
  border-radius: 8px;
  padding: 20px;
  transition: 0.4s ease-in-out;

  &.visible {
    transform: translate(-50%, 0%);
  }
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px solid $c_gray;
  border-radius: 8px;

  li {
    &:last-child {
      border-top: 1px solid $c_gray;
    }
  }
  a {
    display: flex;
    align-items: center;

    padding: 12px 24px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  span {
    margin-left: 12px;
    line-height: 27px;
    font-weight: 400;
  }

  button {
    display: flex;
    align-items: center;
    padding: 12px 24px;

    width: 100%;

    background: #fff;
    border-radius: 8px;

    cursor: pointer;
  }
}
