@import '@/assets/styles/templates.module.scss';

.wrapper {
  @include flex-gap(4px);

  width: fit-content;

  padding: 4px;

  border: 1px solid $c_gray-light;
  border-radius: $br_default;
}

.button {
  width: 40px;
  height: 40px;

  padding: 0;

  cursor: pointer;
  border-radius: $br_default;
  background-color: white;

  transition: 0.15s ease-in-out;

  &:hover {
    background-color: $c_accent-light;
  }
}

.buttonPrevious,
.buttonNext {
  @extend .button;
}

.buttonPrevious:hover,
.buttonNext:hover {
  background-color: $c_accent-light;
}

.active {
  background-color: $c_accent;
  color: white;

  &:hover {
    background-color: $c_accent;
  }
}

.dots {
  display: flex;
  width: 11px;
  margin: auto 20px auto 10px;
  color: $c_accent;
  font-weight: 600;
  font-size: 18px;
}

@media screen and (max-width: 550px) {
  .wrapper {
    margin-top: 0;
  }

  .button {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .dots {
    margin: auto 10px auto 0px;
  }

  .button:first-child,
  .button:last-child {
    display: none;
  }
}
