@import '/assets/styles/templates.module';

.footer {
  background: #1e1e1e;
  color: $c_gray-light;
  padding: 60px 0;
  width: 100%;
}
.footerVisibility {
  display: none;
}
.footerContainer {
  display: flex;
  flex-direction: column;

  max-width: 1360px;
  width: 100%;

  margin: 0 auto;
  padding: 0 20px;
}

.footerNav {
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $c_dark;

  ul {
    display: flex;

    li {
      margin-right: 24px;
    }

    li:last-child {
      margin: 0;
    }
  }

  ul > li > a {
    text-decoration: none;
    color: $c_gray-light;
    font-size: 16px;
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;

  div:first-child {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-items: flex-end;
  }
}

.email {
  text-decoration: none;
  color: $c_gray;
  font-size: 16px;
  font-weight: 600;
  line-height: 170%;
}
.phoneNumber {
  color: $c_gray;
  font-size: 16px;
  font-weight: 600;
  line-height: 170%;
  text-decoration: none;
}

.socials {
  svg > path {
    fill: #fff;
  }
  a:first-child {
    margin-right: 20px;
  }
}

.footerInfo {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  text-align: end;
  p,
  span,
  a {
    color: $c_dark;
    font-size: 16px;
  }
  svg > path {
    fill: #fff;
  }
}
.navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: fit-content;
  div > a:first-child {
    margin-right: 20px;
  }
  div {
    @media screen and (max-width: $m_tablet) {
      display: none;
    }
  }
}

.govLinks {
  display: none;
}

@media screen and (max-width: $m_tablet) {
  .footerNav {
    ul {
      flex-direction: column;

      li {
        margin-bottom: 12px;
      }
    }
  }

  .govLinks {
    display: flex;
    a:first-child {
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: 744px) {
  .contacts {
    gap: 20px;
    div:first-child {
      flex-direction: column;
      gap: 12px;
    }
  }
}

@media screen and (max-width: $m_phone) {
  .footerInfo {
    flex-direction: column;
    text-align: start;
    gap: 24px;
  }
}
