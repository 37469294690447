@import '@/assets/styles/templates.module.scss';

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 32px;
  gap: 10px;

  border-radius: 8px;
  cursor: pointer;
  border: 2px solid transparent;

  font-size: 16px;
  font-weight: 500;

  transition: all 0.15s ease-in-out;
}
.outlined {
  border: 1px solid $c_accent;
  background-color: transparent;
  color: $c_accent;

  @media (hover: hover) {
    &:hover {
      background-color: $c_accent;
      color: white;
    }
  }

  &:active {
    opacity: 0.5;
  }

  &:disabled {
    color: $c_gray;
    border: 1px solid $c_gray;
    background-color: transparent;

    opacity: 0.5;
    pointer-events: none;
  }
}
.filled {
  border: 1px solid $c_accent;
  background-color: $c_accent;
  color: white;

  @media (hover: hover) {
    &:hover {
      background-color: transparent;
      color: $c_accent;
    }
  }

  &:active {
    opacity: 0.5;
  }

  &:disabled {
    border: 1px solid $c_gray;
    background-color: $c_gray;

    opacity: 0.5;
    pointer-events: none;
  }
}
.grey {
  border: 1px solid $c_gray;
  background-color: transparent;
  color: $c_gray;

  @media (hover: hover) {
    &:hover {
      border: 1px solid $c_accent;
      background-color: $c_accent;
      color: white;
    }
  }

  &:active {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.dark {
  border: 1px solid $c_dark;
  border-radius: 5px;
  background-color: transparent;
  color: $c_dark;

  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
    }
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.small {
  padding: 8px 16px;
  font-weight: 500;
  border-width: 1px;
}
