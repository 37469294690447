@import '../../../assets/styles/helpers/mixin';
@import '../../../assets/styles/helpers/variables';
@import '@/assets/styles/templates.module.scss';

.flatCard {
  position: relative;
  max-width: 427px;
  width: 100%;
  min-height: 462px;
}

.flatLink {
  position: absolute;
  top: 0;
  left: 0;
  inset: 0;
  z-index: 10;
}

.imgWrapper {
  display: flex;
  max-width: 427px;
  height: 240px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: $c_gray-light;
  background-size: 100%;

  span {
    position: absolute;
    top: 8px;
    right: 8px;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    background-color: #ffffff;
    border-radius: $br_default;
    padding: 2px 8px;

    z-index: 10;
  }
}

.body {
  padding-top: 20px;
}

.time {
  color: $c_gray;
  font-size: $text-small;
  line-height: $text-small-height;
}

.about {
  margin-top: 22px;
}

.status {
  background: $c_accent-light;
  padding: 2px 8px;
  color: $c_accent;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  border-radius: 5px;
}

.info {
  font-size: $fs_default;
  line-height: 170%;
  font-weight: 600;
  display: flex;
  gap: 8px;
  margin-top: 10px;

  @include _480 {
    font-size: $title-mob;
    line-height: 170%;
  }
}

.options {
  display: flex;
  color: $c_dark;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  align-items: baseline;
  @include _480 {
    flex-direction: column;
  }
}

.address {
  max-height: 55px;

  color: $c_gray;
  margin-top: 8px;
  font-weight: 400;
  line-height: 170%;
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.price {
  font-weight: 600;
  font-size: $title;
  line-height: 130%;
  color: $c_accent;
}

.additional {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  span {
    font-weight: 400;
  }
}
.seller {
  font-weight: 600;
  color: $c_accent;
}
.header {
  @include flex-gap(20px);
  align-items: center;
  justify-content: space-between;

  width: 100%;

  span {
    color: $c_gray;
    font-size: $text-small;
    line-height: $text-small-height;
  }
}
